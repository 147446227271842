.base {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: stretch;
}

.rest {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  align-items: stretch;
  overflow: hidden;
  position: relative;
}

.rest > * {
  flex: 1;
}

.floaty {
  position: absolute;
  z-index: 4;
}

@media print {
  .rest {
    position: static;
    flex: 0 0 auto;
    overflow: auto;
  }
}

.SaveButton2,
.SaveButton3 {
  background-color: #3333CC;
  padding: 10px 20px;
  line-height: 1.25rem;
  display: inline-block;
  margin: auto 0 auto 15px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  color: white;
  border-radius: 0.5rem;

  position: absolute;
  z-index: 4;
  bottom: 1%;
}

.SaveButton2 {
  left: 44%;
}

.SaveButton3 {
  left: 20%;
}

.CreateSessionFlow {
  background: #fff;
  padding: 50px 20px;
  width: 65%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 3px rgba(0, 0, 0, 0.1);
  border-radius: 1.25em;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  top: 12%;
  margin: auto;
}
.CreateSessionFlow label {
  display: flex;
  flex-direction: row-reverse;
}
.CreateSessionFlow button {
  background-color: #3333CC;/*#ff9900;*/
  border: none;
  display: inline-block;
  align-items: center;
  padding: 5px 10px;
  margin: 25px 30px 35px 30px;
  font-size: 16px;
  line-height: 1.8rem;
  width: 20%;
  border-radius: 0.5rem;
  cursor: pointer;
  vertical-align: middle;
  color: white;
}

.CreateSessionFlow h2 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.CreateSessionFlow p {
  /* color: blue; */
  font-size: 15px;
  line-height: 1.2rem;
  margin: 2px;
}

.CreateSessionFlow input {
  border-radius: 1rem;
}

.CreateSessionFlow select {
  border-radius: 1rem;
}

.CreateSessionFlow small {
  float: right;
}

.CreateSessionFlow svg {
  margin-top: 5px;
  margin-right: -5px;
  margin-left: 5px;
}
