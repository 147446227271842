.base {
  height: 60px;
  padding: 0 30px;
  position: relative;
  z-index: 5;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  transition: opacity 100ms 400ms linear, transform 500ms ease;
  opacity: 0;
  transform: translateY(-75px);
}

.visible {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0ms;
}

.left,
.center,
.right {
  flex: 1 1 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  height: 50px;
  margin-left: 20px;
}

.behindLogo {
  height: 50px;
  position: absolute;
  left: 20px;
  top: 15px;
  z-index: 4;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.base h1 {
  font-weight: 300;
}

.headings {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.headings * {
  white-space: nowrap;
  position: absolute;
  transition: transform 1000ms ease, opacity 1000ms linear;
  opacity: 1;
}

.left button {
  position: relative;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  font-size: medium;
  margin-left: 0.5em;
  background: #174892;/* #ff9900;*/
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  color: white;
}

.right button {
  position: relative;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  font-size: medium;
  margin-left: 0.5em;
  background: #174892;/*#ff9900;*/
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  color: white;
}

.right .search button {
  color: #174892;
}

.right button:disabled {
  position: relative;
  background: white;
  color: gray;
  cursor: text;
}

@media print {
  .base {
    display: none;
  }
}
