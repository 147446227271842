.base {
  line-height: 1.6;
  white-space: pre-wrap;
  transition: margin 300ms ease;
}

.base span {
  /* display: inline-block; */
  line-height: 1.4;
}

.entering {
  margin-bottom: -2.6em;
}
