.phi {
  background: #999999;
}

.condition {
  background: #5cceff;
}

.anatomy {
  background: #d185af;
}

.medication {
  background: #f0e442;
}

.treatment {
  background: #44aa99;
}

.diag {
  background: #808000;
}
