.base {
  font-size: 15px;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(110%);
  display: flex;
  flex-direction: row;
  transition: transform 500ms ease;
  box-sizing: border-box;
  background: #ddd;
  z-index: 4;
}

.visible {
  transform: translateY(0);
}

.pageContainer {
  margin: 3vw;
  width: 65vw;
  display: flex;
  flex-direction: column;
  transform: translateY(5vw);
  opacity: 0;
  transition: transform 600ms ease, opacity 1000ms linear;
}

.visible .pageContainer {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 600ms;
}

.page {
  flex: 1;
  background: #fff;
  padding: 3vw;
  text-align: left;
  box-sizing: border-box;
  box-shadow: 0 1vw 3vw rgba(0, 0, 0, 0.3);
  font-size: 0.7vw;
  overflow: auto;
}

.page p {
  white-space: pre-line;
}

.logo {
  background: url(../img/logo_acme_01@2x.png) left center no-repeat;
  background-size: 16em 5.6em;
  height: 5.6em;
}

.transcript p {
  margin: 0vw;
  padding: 0vw;
}

.actions {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 500ms linear;
}

.visible .actions {
  opacity: 1;
  transition-delay: 1400ms;
}

.actions button {
  background: #fff;
  width: 300px;
  font-size: 20px;
  margin: 20px;
  border-radius: 8px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.actions button:before {
  content: '';
  width: 40px;
  height: 50px;
  margin: 5px 15px 0;
}

.ehr:before {
  background: url(../img/icon_ehr_01.svg);
}

.pdf:before {
  background: url(../img/icon_pdf_01.svg);
}

.csv:before {
  background: url(../img/icon_csv_01.svg);
}

.base dl {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0;
  flex: 1;
  align-items: baseline;
}

.base dt,
.base dd {
  flex: 0 0 30%;
  margin: 0;
  padding: 0.25em 0;
  box-sizing: border-box;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.08); */
}

.base dt {
  font-weight: bold;
  text-align: right;
  padding-right: 1em;
  font-size: 0.75em;
  /* padding-top: .6em; */
  text-transform: uppercase;
}

.base dd {
  flex: 0 0 70%;
}

.result {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  align-items: center;
}

.result:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}

.result h4 {
  flex: 0 0 25%;
  margin: 0;
  padding: 0;
  padding-left: 1em;
}

@media print {
  .base {
    overflow: visible;
    position: static;
    height: auto;
  }

  .logo {
    content: url(../img/logo_acme_01@2x.png) !important;
  }

  .actions {
    display: none;
  }

  .page {
    position: static;
    box-shadow: none;
    font-size: 12px;
    width: 100%;
    height: auto;
  }

  .result {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }
}
